import React  from 'react'
import moment from 'moment'

import {
    AdminPanel,
    WalletsHeader,
    WalletsContent,
    EditContactForm,
    WalletsFilters,
    SelectedWallets
} from 'components'
import { generate, normalize, compare, hash, find } from 'tools'

import * as itemsFilters from './filters'
import connector         from './connector'
import './wallets.scss'

const
    page = 200,
    defFilters = {
        connections: true,
        contacts:    true,
        owner:       [],
        dates:       [ 'any' ],
        tags:        [],
        event:       []
    },
    extractFilters = base => {
        const
            res = { ...base },
            params = hash.extract()

        if ( params.type ) {
            ( params.type === 'contact' ) && ( res.connections = false )
            ;( params.type === 'connection' ) && ( res.contacts = false )
        }

        ( params.owner ) && ( res.owner = params.owner.split( ',' ))
        ;( params.tags ) && ( res.tags = params.tags.split( ',' ))
        ;( params.event ) && ( res.event = params.event.split( ',' ))
        ;( params.period ) && ( res.dates = [ params.period ])
        ;( params.dateFrom ) && ( res.dates[ 1 ] = params.dateFrom )
        ;( params.dateTo ) && ( res.dates[ 2 ] = params.dateTo )

        return res
    }

class Wallets extends React.Component {

    constructor ( props ) {
        super( props )

        this.state = {
            edit:     false,
            drawer:   false,
            catched:  false,
            selected: [],

            current: null,
            search:  hash.extract( 'search' ),
            filters: extractFilters( defFilters ),

            links:         null,
            appendLoading: false
        }

        this.set = generate.set( this )
    }

    componentDidMount () {
        this.load()
        this.props.flushLastId()
    }

    componentDidUpdate ( prevProps, prevState ) {
        const
            { personal, account, user, match, history, lastId } = this.props,
            { filters, search, current, catched, selected, appendLoading } = this.state

        if ( lastId !== prevProps.lastId && lastId ) {
            history.push( `/sharing/${lastId}/edit` )
            return
        }

        ( account !== prevProps.account ) && ( this.load())

        if ( personal && user && account === user.id ) {
            const
                { links, loaded } = personal

            if ( links !== prevProps.personal.links ) {
                this.set.appendLoading( false )
                this.set.links( normalize.links( links, user ))
                ;( match.params.id && !catched ) && ( this.select({ id: match.params.id })())
            }

            ( !loaded && !appendLoading ) && ( this.loadMore())
        }

        if ( user && account !== user.id && window.location.pathname !== '/corporate/wallets' ) {
            history.push( `/corporate/wallets?company=${account}` )
        }

        if ( filters !== prevState.filters ) {
            if ( filters.dates[ 0 ] === 'any' && filters.dates.length > 1 ) {
                this.set.filters({ ...filters, dates: [ 'any' ] })
            }
        }

        if ( current !== prevState.current && !current ) {
            ( account === user.id )
                ? hash.router.replace( '/wallets' )
                : hash.router.replace( '/corporate/wallets' )
        }

        ( search !== prevState.search ) && ( this.setFilters( filters ))
        ;( selected !== prevState.selected ) && ( this.close())
    }

    load = ( limit = 20 ) => {
        const
            { user, personal, account } = this.props

        this.set.state({
            links:   null,
            filters: extractFilters( defFilters )
        })

        if ( account === user.id ) {
            ( !personal.links )
                ? this.loadLinks( limit )
                : this.set.links( normalize.links( personal.links, user ))

            this.props.getSharingLists()
        } else {
            this.set.state({
                links:   [],
                filters: extractFilters( defFilters )
            })
        }
    }

    loadLinks = limit => {
        this.set.appendLoading( true )
        this.props.getLinks({ limit })
    }

    loadMore = () => {
        this.set.appendLoading( true )
        this.props.appendLinks({ limit: page, before_id: this.props.personal.latest })
    }

    select = item => e => {
        const
            { account, user, personal } = this.props,
            isPersonal = account === user.id,

            arr = isPersonal
                ? personal.links
                : [],

            url = isPersonal
                ? '/wallets'
                : '/corporate/wallets'

        if ( e ) {
            if ( e.target && ( e.target.tagName === 'A' || e.target.tagName === 'INPUT' )) { return }
            if ( e.className && e.className.indexOf( 'leading-cell' ) > -1 ) { return }
        } else {
            hash.router.replace( `${url}/${item.id}` )
        }

        const
            current = arr.find( link => link.id === item.id )

        if ( !current ) { return }

        this.set.state({
            current,
            catched: true,
            edit:    true
        })
    }

    toggleDrawer = () => this.set.drawer( !this.state.drawer )
    openDrawer = () => this.set.drawer( true )
    closeDrawer = () => this.set.drawer( false )

    search = e => this.set.search( e.target.value )

    applySearch = ( links, search ) =>
        this.applyFilters(
            links.filter( link => {
                const
                    s = search.toLowerCase()

                return (
                    link.fullName.toLowerCase().indexOf( s ) > -1 ||
                    link.position.toLowerCase().indexOf( s ) > -1 ||
                    link.companyName.toLowerCase().indexOf( s ) > -1
                )
            })
        )

    applyFilters = items => {
        const
            { personal } = this.props,
            { filters } = this.state,
            { links } = personal

        let
            res = [ ...items ]

        Object.keys( filters ).forEach( key => {
            if ( itemsFilters[ key ]) {
                res = res.filter( item => {
                    const
                        raw = links.find( l => l.id === item.id )

                    return itemsFilters[ key ]( filters[ key ])( raw )
                })
            }
        })

        return res
    }

    reset = drop => () => {
        ( drop )
            ? this.setFilters({
                ...this.state.filters,
                [ drop.id ]: defFilters[ drop.id ]
            })
            : this.set.search( '', () => this.setFilters( defFilters ))
    }

    close = () => {
        this.set.state({
            edit:   false,
            drawer: false
        })

        setTimeout(() => {
            this.set.current( null )
        }, 300 )
    }

    findCards = id => {
        const
            { corpCards } = this.props,
            res = corpCards[ id ]

        return res || []
    }

    findTags = id => {
        const
            { corpTags } = this.props,
            res = corpTags[ id ]

        return res || []
    }

    selected = data =>
        (
            data && this.state.selected.length > 0
                ? this.state.selected.map( id => data.find( w => w.id === id ))
                : []
        )

    setFilters = payload => {
        const
            { account, user } = this.props,
            { search } = this.state,
            params = {}

        ;( !!search ) && ( params.search = search )
        ;( account !== user.id ) && ( params.company = account )

        if ( !payload.connections || !payload.contacts ) {
            params.type = ( payload.connections )
                ? 'connection'
                : 'contact'
        }

        ( payload.owner.length > 0 ) && ( params.owner = payload.owner.join( ',' ))
        ;( payload.tags.length > 0 ) && ( params.tags = payload.tags.join( ',' ))
        ;( payload.event.length > 0 ) && ( params.event = payload.event.join( ',' ))

        if ( payload.dates[ 0 ] !== 'any' ) {
            params.period = payload.dates[ 0 ]

            if ( payload.dates[ 0 ] === 'specific' ) {
                params.dateFrom = payload.dates[ 1 ]
                params.dateTo = payload.dates[ 2 ]
            }
        }

        hash.apply( params )
        this.set.filters( payload )
    }

    create = data => {
        generate.wait()

        ;( data.is_fixed ) && ( delete data.active_reminders )
        ;( data.id === null ) && ( delete data.id )

        delete data.name
        delete data.type
        delete data.filter
        delete data.expire
        delete data._date_disable
        delete data.activity_threshold
        delete data.export_url

        data.created = moment().unix()
        data.owner_user = this.props.user.id

        this.props.createSharing( data )
    }

    createDynamic = () => {
        const
            { filters } = this.state,
            data = {
                id:               null,
                events:           filters.event.length > 0 ? filters.event : [],
                tags:             filters.tags.length > 0 ? filters.tags : [],
                is_filter_events: filters.event.length > 0,
                is_filter_tags:   filters.tags.length > 0,
                date_disable:     null,
                is_private:       true,
                is_fixed:         false
            }

        find.dates( filters.dates, data )
        this.create( data )
    }

    createFixed = () => {
        const
            { selected } = this.state,
            data = {
                id:           null,
                name:         '',
                links:        selected,
                date_disable: null,
                is_private:   true,
                is_fixed:     true
            }

        this.create( data )
    }

    createPublic = () => {
        const
            { selected } = this.state,
            data = {
                id:           'draft',
                name:         '',
                links:        selected,
                date_disable: null,
                is_private:   false,
                is_fixed:     true,
                password:     generate.passcode()
            }

        this.create( data )
    }

    assignTags = tags => {
        console.log( 'assign', tags, 'to', this.state.selected )
    }

    catchPanel = ref => this._panel = ref
    panelToggle = () => this._panel.expand( 'admin' )()

    render () {
        const
            { match, user, cards, tags, personal, companies, account } = this.props,
            { links, search, filters, drawer, current, edit, selected } = this.state,
            { loaded } = personal,

            isPersonal = account === user.id,
            applied = !compare.objects( filters, defFilters ),

            visible = links
                ? links.filter( link => !link.is_hide && !link.is_archived )
                : [],

            filtered = links
                ? (
                        search
                            ? this.applySearch( links, search )
                            : this.applyFilters( applied ? links : visible )
                    )
                : links,

            fcards = user && isPersonal
                ? cards && cards.filter( card => !card.parent )
                : this.findCards( account ),

            ftags = user && isPersonal
                ? tags
                : this.findTags( account )

        return (
            <React.Fragment>

                <AdminPanel
                    account = { this.props.current }
                    user = { user }
                    companies = { companies }
                    currentPath = { match.path }

                    catchBack = { this.catchPanel }
                    onExpand = { this.closeDrawer }
                    onAccount = { this.props.setAccount }
                    onSignOut = { this.props.signOut }
                />

                <div className="admin-dashboard wallets-dashboard">
                    <WalletsHeader
                        account = { this.props.current }
                        loading = { isPersonal ? !loaded : !links }
                        search = { search }

                        total = { user ? user.links_total : null }
                        count = { links ? visible.length : 0 }
                        hidden = { links && filtered.length > 0 ? visible.length - filtered.length : null }
                        filtered = { applied }
                        filters = { drawer }

                        onTogglePanel = { this.panelToggle }
                        onSearch = { this.search }
                        onReset = { this.reset }
                        onFilters = { this.toggleDrawer }
                    />

                    <div className="admin-dashboard-content">
                        <WalletsContent
                            all = { links }
                            wallets = { filtered }
                            filters = { filters }
                            tags = { tags }
                            selected = { selected }
                            current = { current }

                            search = { search }
                            open = { drawer }
                            filtered = { applied }
                            corporate = { user && account !== user.id }

                            onReset = { this.reset }
                            onRow = { this.select }
                            onClose = { this.close }
                            onFilter = { this.setFilters }
                            onSelect = { this.set.selected }
                        />
                        {/* ( !drawer && !edit ) && ( <FloatingButton /> ) */}
                        <WalletsFilters
                            open = { drawer }
                            filtered = { applied }
                            corporate = { user && account !== user.id }

                            filters = { filters }
                            wallets = { links }
                            cards = { fcards }
                            tags = { ftags }

                            onClose = { this.close }
                            onFilter = { this.setFilters }
                            onReset = { this.reset }
                            onShare = { this.createDynamic }
                        />
                        <SelectedWallets
                            show = { user && user.username === 'leo' }
                            open = { selected.length > 0 }
                            data = { this.selected( filtered ) }
                            tags = { ftags }

                            onCreate = { this.createFixed }
                            onShare = { this.createPublic }
                            onAssign = { this.assignTags }
                        />
                        <EditContactForm
                            editable = { false }
                            open = { edit }
                            contact = { current }
                            tags = { tags }

                            onClose = { this.close }
                            onSave = { this.close }
                        />
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

export default connector( Wallets )
