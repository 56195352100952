import { request, copy } from 'tools'
import * as api          from './api'

export const setRemoving = request.update(
    'storage.sharing.lists.remove.set',
    ( id, state ) => {
        const
            lists = copy.array( state.sharing.lists.data ),
            found = lists.find( l => l.id === id ),
            index = lists.indexOf( found )

        found._removing = true
        lists[ index ] = found

        return {
            ...state,
            sharing: {
                ...state.sharing,
                lists: {
                    ...state.sharing.lists,
                    data: lists
                }
            }
        }
    }
)

export const flushLastId = request.update(
    'storage.sharing.lastId.flush',
    ( n, state ) => ({
        ...state,
        sharing: {
            ...state.sharing,
            lastId: null
        }
    })
)

export const flushSharingError = request.update(
    'storage.sharing.error.flush',
    ( n, state ) => ({
        ...state,
        sharing: {
            ...state.sharing,
            error: null
        }
    })
)

export const flushSharedList = request.update(
    'storage.sharing.list.flush',
    ( n, state ) => ({
        ...state,
        sharing: {
            ...state.sharing,
            current: null
        }
    })
)

export const getSharedInfo = request.asyncUpdate(
    'storage.sharing.list.info.get',
    id => api.getSharedInfo({ id }),
    ( event, state ) => {
        if ( event.status > 299 ) {
            return {
                ...state,
                sharing: {
                    ...state.sharing,
                    neterror: event,
                    current:  false
                }
            }
        }

        return {
            ...state,
            sharing: {
                ...state.sharing,
                current: event.data
            }
        }
    }
)

export const accessSharedInfo = request.asyncUpdate(
    'storage.sharing.list.info.access',
    params => api.getSharedInfo( params ),
    ( event, state ) => {
        if ( event.status > 299 ) {
            return {
                ...state,
                sharing: {
                    ...state.sharing,
                    error: event
                }
            }
        }

        return {
            ...state,
            sharing: {
                ...state.sharing,
                current: event.data
            }
        }
    }
)

export const getSharedList = request.asyncUpdate(
    'storage.sharing.list.get',
    id => api.getSharedList({ id }),
    ( event, state ) => {
        if ( event.status > 299 ) {
            return { ...state }
        }

        return {
            ...state,
            sharing: {
                ...state.sharing,
                current: {
                    ...state.sharing.current,
                    items: event.data
                }
            }
        }
    }
)

export const accessSharedList = request.asyncUpdate(
    'storage.sharing.list.access',
    params => api.getSharedList( params ),
    ( event, state ) => {
        if ( event.status > 299 ) {
            return { ...state }
        }

        return {
            ...state,
            sharing: {
                ...state.sharing,
                current: {
                    ...state.sharing.current,
                    items: event.data
                }
            }
        }
    }
)

export const createSharing = request.asyncUpdate(
    'storage.sharing.lists.create',
    data => api.createSharing( data ),
    ( event, state ) => {
        if ( event.status > 299 ) {
            return { ...state }
        }

        const
            lists = state.sharing.lists.data
                ? copy.array( state.sharing.lists.data )
                : []

        lists.push( event.data )

        return {
            ...state,
            sharing: {
                ...state.sharing,
                lists: {
                    ...state.sharing.lists,
                    data: lists
                },
                lastId: event.data.id
            }
        }
    }
)

export const removeSharing = request.asyncUpdate(
    'storage.sharing.lists.remove',
    id => api.updateSharing({ id, is_deleted: true }),
    ( event, state ) => {
        if ( event.status > 299 ) { return { ...state } }

        const
            id = event.config.url.split( '/' ).pop(),
            lists = copy.array( state.sharing.lists.data ),
            found = lists.findIndex( l => l.id === id )

        lists.splice( found, 1 )

        return {
            ...state,
            sharing: {
                ...state.sharing,
                lists: {
                    ...state.sharing.lists,
                    data: lists
                }
            }
        }
    }
)

export const updateSharing = request.asyncUpdate(
    'storage.sharing.lists.update',
    data => api.updateSharing( data ),
    ( event, state ) => {
        if ( event.status !== 200 ) {
            return { ...state }
        }

        const
            id    = event.config.url.split( '/' ).pop(),
            lists = state.sharing.lists && state.sharing.lists.data
                ? copy.array( state.sharing.lists.data )
                : [],
            found = lists.findIndex( list => list.id === id ),
            isCurrent = state.sharing.current && state.sharing.current.id === id

        lists[ found ] = event.data

        return {
            ...state,
            sharing: {
                ...state.sharing,
                current: isCurrent ? event.data : state.sharing.current,
                lists:   {
                    ...state.sharing.lists,
                    data: lists
                }
            }
        }
    }
)

export const getSharingLists = request.asyncUpdate(
    'storage.sharing.lists.get',
    () => api.getLists(),
    ( event, state ) => {
        const
            updated =  state.sharing.lists.data || []

        event.data.results?.forEach( item => {
            const
                index = updated.findIndex( i => i.id === item.id )

            ;( index > -1 )
                ? updated[ index ] = item
                : updated.push( item )
        })

        return {
            ...state,
            sharing: {
                ...state.sharing,
                lists: {
                    ...event.data,
                    results: null,
                    data:    updated
                }
            }
        }
    }
)

export const refreshSharingList = request.asyncUpdate(
    'storage.sharing.list.refresh',
    id => api.getSharedInfo({ id }),
    ( event, state ) => {
        const
            updated = [ ...state.sharing.lists.data ],
            found = updated.findIndex( i => i.id === event.data.id )

        ;( found > -1 ) && ( updated[ found ] = event.data )

        return {
            ...state,
            sharing: {
                ...state.sharing,
                lists: {
                    ...state.sharing.lists,
                    data: updated
                }
            }
        }
    }
)

export const exportSharingCSV = request.asyncUpdate(
    'storage.sharing.export.csv',
    id => api.exportCSV( id ),
    ( event, state ) => ({
        ...state,
        sharing: {
            ...state.sharing,
            csv: event.data
        }
    })
)

export const flushSharingCSV = request.update(
    'storage.sharing.export.csv.flush',
    ( _, state ) => ({
        ...state,
        sharing: {
            ...state.sharing,
            csv: null
        }
    })
)
