export const uniq = () => '_' + Math.random().toString( 36 ).substr( 2, 9 )

const
    passCodePhrase = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
    passCodeLength = 10

export const passcode = () =>
    ( new Array( passCodeLength ))
        .fill( 1 )
        .map(() => passCodePhrase[ Math.round( Math.random() * passCodePhrase.length ) ])
        .join( '' )

export const set = cmp => {
    const
        res = {},
        keys = Object.keys( cmp.state )

    keys.forEach( key => {
        res[ key ] = ( val, cb ) => cmp.setState({ [ key ]: val }, typeof cb === 'function' ? cb : void 0 )
    })

    res.state = ( data, cb ) => cmp.setState( data, typeof cb === 'function' ? cb : void 0 )
    res.byval = ( key, cb ) => e => res[ key ]( e ? e.target.value : '', cb )
    res.bycheck = ( key, cb ) => e => res[ key ]( e ? e.target.checked : '', cb )
    res.toggle = ( key, cb ) => () => res[ key ]( !cmp.state[ key ], cb )
    res.delayed = ( key, cb ) => val => () => res[ key ]( val, cb )
    res.deval = ( key, cb ) => e => res[ key ]( e ? e.target.value : '', cb )
    res.decheck = ( key, cb ) => e => res[ key ]( e ? e.target.checked : '', cb )

    return res
}

export const addState = ( cmp, add ) => {
    cmp.state = cmp.state
        ? { ...cmp.state, ...add }
        : add

    cmp.set = set( cmp )
}

export const range = ( min, max, base ) => {
    const
        zero = base || 0,
        res = []

    for ( let i = zero + min; i <= zero + max; i++ ) {
        res.push( i )
    }

    return res
}

export const wait = () => {
    document.getElementById( 'root' ).style.pointerEvents = 'none !important'
    document.getElementById( 'root' ).style.opacity = '.5'
    document.body.style.cursor = 'wait'
}

export const unwait = () => {
    document.getElementById( 'root' ).style.pointerEvents = ''
    document.getElementById( 'root' ).style.opacity = ''
    document.body.style.cursor = ''
}

export const download = ( type, data, filename ) => {
    const
        a = document.createElement( 'a' )

    a.href = `data:${type},${data}`
    a.setAttribute( 'download', filename || 'untitled' )

    a.click()
}
