/* IMPORT */
import { connect }                                                                    from 'react-redux'
import { walletsActions, userActions, authActions, sharingActions, corporateActions } from 'services'

/* EXPORT */

const
    mapStateToProps = state => ({
        personal:  state.wallets.user,
        corporate: state.wallets.corporate,
        corpCards: state.wallets.cards,
        corpTags:  state.wallets.tags,
        company:   state.companies.current,
        user:      state.user.info,
        companies: state.user.companies,
        account:   state.user.account,
        current:   state.user.current,
        cards:     state.user.cards,
        tags:      state.user.tags,
        lastId:    state.sharing.lastId,
    }),

    allActions = {
        ...corporateActions,
        ...walletsActions,
        ...userActions,
        ...authActions,
        ...sharingActions
    }

export default connect( mapStateToProps, allActions )
