
import moment from 'moment'

import t from '@tabee/frontend-i18n'

import countries       from './countries'
import * as navigation from './navigation'

const scrolls =
    {
        renderTrackVertical:     props => <div {...props} className="v-scroll-track" />,
        renderThumbVertical:     props => <div {...props} className="v-scroll-thumb" />,
        renderTrackHorizontal:   props => <div {...props} className="h-scroll-track" />,
        renderThumbHorizontal:   props => <div {...props} className="h-scroll-thumb" />,
        hideTracksWhenNotNeeded: true
    },

      dates = {
          def:     'YYYY-MM-DDTHH:mm:ss',
          classic: 'DD.MM.YYYY',
          nice:    'D MMMM, YYYY',
          time:    'HH:mm',
          date:    'YYYY-MM-DD'
      },


      apiUrl = process.env.REACT_APP_BASE,

      config =
    {
        title:     'Tabee Admin',
        indexPage: '/wallets',

        sso: { url: process.env.REACT_APP_SSO },
        id:  { url: process.env.REACT_APP_ID },

        api: {
            url:       apiUrl,
            noHttpUrl: apiUrl.replace( 'http:', '' ).replace( 'https:', '' ).replace( /^\/\//, '' ),
            project:   '5ff3739e-03be-11eb-801b-6a13bef2453b'
        },

        scrolls,
        navigation,
        countries,

        forms: {
            layout: {
                labelCol:   { span: 8 },
                wrapperCol: { span: 16 },
            }
        },

        images: {
            media:          `${apiUrl}/media`,
            resizer:        '//img.tabee.mobi',
            latestFallback: '//tabee.app/files/placeholder_avatar@2x.jpg'
        },

        links: {
            login:   '/login',
            signup:  '/sign-up',
            android: 'https://app.adjust.com/rfdji5l?fallback_lp=https%3A%2F%2Ftabee.app&fallback=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.tabee.android&redirect=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.tabee.android&redirect_ios=https%3A%2F%2Fapps.apple.com%2Fsearch%3Fterm%3DTabee%2520Business%2520Card%2520Creator%2520by%2520Tabee%26entity%3Dsoftware%26country%3DUS%26ct%3Dweb%26mt%3D8%26at%3D110lqC%26ls%3D1',
            ios:     'https://app.adjust.com/rfdji5l?fallback_lp=https%3A%2F%2Ftabee.app&fallback=https%3A%2F%2Fitunes.apple.com%2Fapp%2Fid1005851014%3Fpt%3D119303076%26ct%3Dweb%26mt%3D8%26at%3D110lqC%26ls%3D1&redirect_android=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.tabee.android&redirect_ios=https%3A%2F%2Fapps.apple.com%2Fsearch%3Fterm%3DTabee%2520Business%2520Card%2520Creator%2520by%2520Tabee%26entity%3Dsoftware%26country%3DUS%26ct%3Dweb%26mt%3D8%26at%3D110lqC%26ls%3D1',

        },

        todo: {
            filters: [
                {
                    id:     'all',
                    title:  t( 'todo.todoAll' ),
                    filter: item => !item.completed_date
                },
                {
                    id:     'today',
                    title:  t( 'todo.todoToday' ),
                    filter: item => moment( item.due_date ).isSame( moment(), 'day' )
                },
                {
                    id:     'overdue',
                    title:  t( 'todo.todoOverdue' ),
                    filter: item => !!item.due_date && moment( item.due_date ).isBefore( moment()) && !item.completed_date
                },
                {
                    id:     'nodue',
                    title:  t( 'todo.todoNoDue' ),
                    filter: item => !item.due_date && !item.completed_date
                },
                {
                    id:     'soon',
                    title:  t( 'todo.todoSoon' ),
                    filter: item => moment( item.due_date ).isAfter( moment())
                },
                {
                    id:     'completed',
                    title:  t( 'todo.todoCompleted' ),
                    filter: item => !!item.completed_date
                }
            ]
        },

        publicEmailCompany: '19f2ebdc-e0a8-11e6-a630-6a13bef2453b',

        defaultDesign: {
            id:                        'default',
            company_bg_color:          '#283340',
            company_text_color:        '#ffffff',
            company_custom_text_color: '#5FC4FF',
        },

        ui: {
            prefix:        '_tabee',
            dates,
            stub:          count => ( new Array( count || 4 ).fill( 0 ).map(( z, i ) => ({ id: i }))),
            notifications: {
                getContainer: () => document.querySelector( '.page-content' ),
                duration:     1
            },
            breakpoints: { mobile: 540 },
            sortConfig:  [
                {
                    id:       'sort',
                    children: {
                        items: [
                            {
                                id:   'firstName',
                                name: 'First Name'
                            },
                            {
                                id:   'lastName',
                                name: 'Last Name'
                            },
                            {
                                id:   'companyName',
                                name: 'Company'
                            },
                            {
                                id:   'position',
                                name: 'Title'
                            },
                        ],
                    },
                },
            ],
            columnsConfig: [
                {
                    id:       'unified',
                    children: {
                        items: [
                            {
                                id:    'type',
                                value: 'unified',
                                name:  'Unified ID columns',
                                type:  'radio'
                            }
                        ],
                    },
                },
                {
                    id:       'separate',
                    children: {
                        multiSelection: true,
                        items:          [
                            {
                                id:    'type',
                                value: 'separate',
                                name:  'Separate ID columns',
                                type:  'radio'
                            },
                            {
                                id:   'photo',
                                name: 'Photo',
                                weak: cfg => cfg.type !== 'separate'
                            },
                            {
                                id:   'logo',
                                name: 'Company Logo',
                                weak: cfg => cfg.type !== 'separate'
                            },
                            {
                                id:   'first_name',
                                name: 'First name',
                                weak: cfg => cfg.type !== 'separate'
                            },
                            {
                                id:   'last_name',
                                name: 'Last name',
                                weak: cfg => cfg.type !== 'separate'
                            },
                            {
                                id:   'company',
                                name: 'Company',
                                weak: cfg => cfg.type !== 'separate'
                            },
                            {
                                id:   'title',
                                name: 'Title',
                                weak: cfg => cfg.type !== 'separate'
                            },
                        ],
                    },
                },
                {
                    id:       'rest',
                    children: {
                        multiSelection: true,
                        items:          [
                            {
                                id:   'phone',
                                name: 'Phone',
                            },
                            {
                                id:   'email',
                                name: 'Email',
                            },
                            {
                                id:   'tags',
                                name: 'Tags',
                            },
                            {
                                id:   'event',
                                name: 'Event',
                            },
                            {
                                id:   'industry',
                                name: 'Industry',
                            },
                        ],
                    },
                },
            ]
        }
    }

export default Object.freeze( Object.assign({}, config ))
