import { request } from 'tools'

export const getLists = () => request.get( 'filter_export' )
export const updateSharing = ({ id, ...body }) => request.patch( `filter_export/${id}`, body )
export const createSharing = body => request.post( 'filter_export', body )

export const getSharedInfo = ({ id, ...params }) => request.get( `filter_export/${id}`, params )
export const getSharedList = ({ id, ...params }) => request.get( `filter_export/${id}/links`, params )

export const exportCSV = id => new Promise(( resolve, reject ) => {
    request.get( `filter_export/${id}/hash` ).then(( hashData ) => {
        if ( !hashData?.data?.export_hash ) {
            reject()
            return
        }

        request.get( `filter_export/${id}/csv/${hashData.data.export_hash}` ).then( data => resolve( data ))
    })
})
