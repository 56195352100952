const
    tags  = link => link.tags || withManagement( link ).link_management.tags_list || [],
    event = link => link.event || withManagement( link ).link_management.event || null,

    withManagement = link => ({
        ...link,
        link_management: link.link_management || link
    }),

    email = link => {
        if ( link.recipient && link.recipient.email ) {
            return link.recipient.email.contact
        }

        if ( link.contacts ) {
            const
                res = []

            for ( let i = 0; i < link.contacts.length; i++ ) {
                if ( link.contacts[ i ].contact_type === 'email' ) {
                    res.push( link.contacts[ i ].contact_value )
                }
            }

            return res
        }

        if ( link.owner_card && link.owner_card.email ) {
            return link.owner_card.email.contact
        }

        return ( link.invitation && link.invitation.indexOf( '@' ) > -1 )
            ? link.invitation
            : ''
    },

    photo = link => {
        let
            res = link.photo || ''

        if ( link.link_management && link.link_management.photo ) {
            res = link.link_management.photo
        }

        if ( link.recipient ) {
            if ( link.recipient.owner_user && link.recipient.owner_user.avatar ) {
                res = link.recipient.owner_user.avatar
            }
        }

        return res
    },

    firstName = link => {
        let
            res = link.name || ''

        if ( link.recipient && link.recipient.first_name ) {
            res = link.recipient.first_name
        }

        if ( link.owner_card && link.owner_card.first_name ) {
            res = link.owner_card.first_name
        }

        if ( link.link_customisation && link.link_customisation[ 0 ] && link.link_customisation[ 0 ].name ) {
            res = link.link_customisation[ 0 ].name
        }

        return res
    },

    lastName = link => {
        let
            res = link.surname || ''

        if ( link.recipient && link.recipient.last_name ) {
            res = link.recipient.last_name
        }

        if ( link.owner_card && link.owner_card.last_name ) {
            res = link.owner_card.last_name
        }

        if ( link.link_customisation && link.link_customisation[ 0 ] && link.link_customisation[ 0 ].surname ) {
            res = link.link_customisation[ 0 ].surname
        }

        return res
    },

    name = link => {
        let
            res = `${firstName( link )} ${lastName( link )}`

        return res ? res.trim() : '—'
    },

    phone = link => {
        let
            res = ''

        if ( link.contacts ) {
            res = []

            for ( let i = 0; i < link.contacts.length; i++ ) {
                if ( link.contacts[ i ].contact_type === 'phone' ) {
                    res.push( link.contacts[ i ].contact_value )
                }
            }

            return res
        }

        if ( link.link_management && link.link_management.link_contacts ) {
            link.link_management.link_contacts.every( contact => {
                if ( contact.contact_type === 'phone' ) {
                    res = contact.contact_value || ''
                    return false
                }

                return true
            })
        }

        if ( link.owner_card && link.owner_card.phone ) {
            res = link.owner_card.phone.contact
        }

        if ( link.recipient ) {
            if ( link.recipient.phone && link.recipient.phone.contact ) {
                res = link.recipient.phone.contact
            }
        }

        return res
    },

    position = link => {
        let
            res = link.position || ''

        if ( link.link_customisation ) {
            link.link_customisation.every( custom => {
                if ( custom.position ) {
                    res = custom.position || ''
                    return false
                }

                return true
            })
        }

        if ( link.owner_card && link.owner_card.position ) {
            res = link.owner_card.position
        }

        if ( link.recipient ) {
            if ( link.recipient.position ) {
                res = link.recipient.position
            }
        }

        return res
    },

    company = link => {
        let
            res = link.company || ''

        if ( link.domain && link.domain.company ) {
            res = link.domain.company.name || ''
        }

        if ( link.link_customisation ) {
            link.link_customisation.every( custom => {
                if ( custom.company ) {
                    res = custom.company || ''
                    return false
                }

                return true
            })
        }

        if ( link.owner_card && link.owner_card.company ) {
            res = link.owner_card.company
        }

        return res
    },

    logo = link => {
        let
            res = ''

        if ( link.domain && link.domain.company ) {
            res = link.domain.company.square_logo || res
        }

        if ( link.recipient && link.recipient.company_owner ) {
            res = link.recipient.company_owner.square_logo || res
        }

        return res
    },

    file = link => {
        if ( link.file ) { return link.file }

        let
            res = link.file || link.photo_card_face || ''

        if ( link.files && !res ) {
            for ( let i = 0; i < link.files.length; i++ ) {
                if ( !link.files[ i ].is_deleted ) {
                    res = link.files[ i ].file
                    break
                }
            }
        }

        return res
    },

    l = link => ({
        id:           link.id,
        firstName:    firstName( link ),
        lastName:     lastName( link ),
        fullName:     name( link ),
        phone:        phone( link ),
        email:        email( link ),
        position:     position( link ),
        photo:        photo( link ),
        companyName:  company( link ),
        companyLogo:  logo( link ),
        tags:         tags( link ),
        event:        event( link ),
        industry:     null,
        created:      link.created,
        modified:     link.modified,
        is_favourite: withManagement( link ).link_management.is_favourite,
        is_hide:      withManagement( link ).link_management.is_hide,
        is_archived:  withManagement( link ).link_management.is_archived,
        is_todo:      link.is_todo_exists,
        file:         file( link )
    })

export default l
