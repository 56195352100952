import React from 'react'

import {
    AdminPanel,
    AdminHeader,
    WalletsContent,
    Spinner,
    PasscodePopover,
    EditContactForm,
    SharingModal
} from 'components'
import { generate, normalize, fake, request, hash, t } from 'tools'

import connector from './connector'
import './shared.scss'

class Shared extends React.Component {

    constructor ( props ) {
        super( props )

        this.state = {
            loading:   false,
            timeout:   true,
            accessing: false,
            granted:   false,
            edit:      false,
            editList:  props.match.params.action === 'edit',
            checked:   !request.storage.get( 'user_id' ),

            passcode: '',
            list:     null,
            selected: null
        }

        this.set = generate.set( this )
    }

    componentDidMount () {
        const
            { history, match, user } = this.props,
            id = match.params.id

        if ( !id || id === 'null' ) {
            history.push( '/sharing' )
            return
        }

        generate.unwait()

        this.props.flushSharedList()
        ;( user ) && ( this.props.getSharedInfo( id ))
    }

    componentDidUpdate ( prevProps ) {
        const
            { list, user, match, passerr, neterr } = this.props,
            { passcode, checked } = this.state

        if ( user !== prevProps.user ) {
            ( !!list && !!user ) && ( this.set.checked( true ))

            if ( user && user.id ) {
                this.props.setAccount( user.id )
                this.props.getSharedInfo( match.params.id )
            }
        }

        if ( list !== prevProps.list ) {
            this.set.state({
                loading: !list,
                granted: !!list,
                checked: !request.storage.get( 'user_id' ) || ( !!user && ( !list || list.owner_user !== user.id ))
            })

            if ( list ) {
                ( list.items )
                    ? this.set.list( normalize.links( list.items, user ))
                    : (
                            passcode
                                ? this.props.accessSharedList({ id: list.id, passcode })
                                : this.props.getSharedList( list.id )
                        )
            }
        }

        if ( neterr !== prevProps.neterr ) {
            ( !checked ) && ( this.set.checked( true ))
        }

        if ( passerr !== prevProps.passerr ) {
            this.set.state({
                accessing: false,
                passcode:  passerr ? '' : passcode
            })
        }
    }

    update = data => {
        this.set.state({
            loading:  true,
            editList: false
        })
        hash.router.replace( `/sharing/${data.id}` )
        this.props.updateSharing( data )
    }

    getAccess = () => {
        const
            { match } = this.props,
            { passcode } = this.state

        this.set.accessing( true )
        this.props.accessSharedInfo({ id: match.params.id, passcode })
    }

    select = item => () => {
        const
            { list } = this.props,
            selected = list && list.items && list.items.find( link => link.id === item.id )

        if ( !selected ) { return }

        this.set.state({
            selected,
            edit: true
        })
    }

    close = () => {
        const
            { match } = this.props

        this.set.state({
            edit:     false,
            editList: false
        })

        if ( match.params.action === 'edit' ) {
            hash.router.replace( `/sharing/${match.params.id}` )
        }

        setTimeout(() => {
            this.set.selected( null )
        }, 300 )
    }

    events = () => {
        const
            { wallets } = this.props

        let
            res = []

        if ( !wallets || !wallets.links ) { return res }

        wallets.links.forEach( link => res.push( link.event ))

        return res.filter( item => !!item && ( res.find( i => !!i && i.name === item.name ) === item ))
    }

    catchPanel = ref => this._panel = ref
    panelToggle = () => this._panel.expand( 'admin' )()

    render () {
        const
            { current, user, companies, match, tags, account, all, passerr, neterr } = this.props,
            { list, granted, selected, checked } = this.state,
            { timeout, passcode, accessing, edit, editList } = this.state,
            events = this.events(),
            info = this.props.list,
            loading = this.state.loading || !list,
            forceSelf = ( all.data && !!all.data.find( l => l.id === match.params.id )),
            isOwn = (( info && user && info.owner_user === user.id ) || forceSelf )

        ;( !( isOwn || granted ))
            ? ( document.body.className !== 'blur' && ( document.body.className = 'blur' ))
            : document.body.className = ''

        return (
            <React.Fragment>

                <AdminPanel
                    noLoad

                    account = { current }
                    user = { user }
                    companies = { companies }
                    currentPath = { match.path }

                    catchBack = { this.catchPanel }
                    onExpand = { this.closeDrawer }
                    onAccount = { this.props.setAccount }
                    onSignOut = { this.props.signOut }
                />

                <div className="admin-dashboard wallets-dashboard shared-list-dashboard">
                    <AdminHeader account={current} onTogglePanel={this.panelToggle}>
                        <div className="title-fix">
                            { loading && <Spinner style={{ marginRight: 20 }} /> }
                            {
                                loading
                                    ? t( 'sharing.loadingList' )
                                    : info.title
                            }
                        </div>
                    </AdminHeader>

                    <div className="admin-dashboard-content">
                        <WalletsContent
                            all = { loading && !( isOwn || granted ) ? fake.prePasscodeData : list }
                            wallets = { loading && !( isOwn || granted ) ? fake.prePasscodeData : list }
                            tags = { tags }

                            corporate = { user && account !== user.id }

                            onRow = { this.select }
                        />
                    </div>

                    <EditContactForm
                        editable = { false }
                        open = { edit }
                        contact = { selected }
                        tags = { tags }

                        onClose = { this.close }
                        onSave = { this.close }
                    />

                    <PasscodePopover
                        login = { false }
                        checked = { ( !request.storage.get( 'user_id' ) || (( info || neterr ) && user )) && checked }
                        lock = { !( isOwn || granted ) }
                        visible = { !forceSelf && ( list || timeout ) && !( isOwn || granted ) }
                        loading = { accessing }

                        wrongCode = { passerr }

                        user = { user }
                        list = { this.props.list || null }
                        passcode = { passcode }

                        onAuth = { this.props.auth }
                        onAccess = { this.getAccess }
                        onPasscode = { code => {
                            this.set.passcode( code )
                            ;( passerr ) && ( this.props.flushSharingError())
                        }}
                    />

                    {
                        ( isOwn && tags ) && (
                            <SharingModal
                                open = { this.props.list && isOwn && tags && editList && !loading }
                                list = { this.props.list }

                                tags = { tags }
                                events = { events }

                                onAdd = { this.make }
                                onSave = { this.update }
                                onClose = { this.close }
                            />
                        )
                    }
                </div>

            </React.Fragment>
        )
    }
}

export default connector( Shared )
