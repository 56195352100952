import React                                from 'react'
import { Skeleton, Button, Dropdown, Menu } from 'antd'
import moment                               from 'moment'

import { Icons, Spinner }  from 'components'
import { sorter, stub, t } from 'tools'

const columns = cmp => ([
    {
        title:        '',
        dataIndex:    'is_private',
        width:        60,
        onHeaderCell: () => ({ className: 'leading-cell' }),
        onCell:       () => ({ className: 'leading-cell' }),
        render:       ( flag, row ) =>
            (
                (( flag || row.passcode !== '' ) && row.date_disable && moment.unix( row.date_disable ).isAfter( moment()))
                    ? <Icons.Linked />
                    : <Icons.Unlinked />
            ),
        stub: () => <Skeleton.Input active style={{ width: 32 }} />
    },
    {
        title:     t( 'columns.columnsTitle' ),
        dataIndex: 'title',
        width:     200,
        sorter:    sorter.locale( 'title' ),
        render:    title =>
            (
                title
                    ? <span className="no-wrap-line" title={title}>{ title }</span>
                    : '—'
            ),
        stub: () => stub.line
    },
    {
        title:     t( 'columns.columnsType' ),
        dataIndex: 'is_private',
        width:     150,
        sorter:    sorter.bool( 'is_private' ),
        render:    priv => priv ? t( 'global.private' ) : t( 'global.public' ),
        stub:      () => stub.line
    },
    {
        title:     t( 'columns.columnsStatus' ),
        dataIndex: 'date_disable',
        width:     150,
        sorter:    ( a, b ) => {
            const
                now = moment(),
                disA = !!a.date_disable && moment.unix( a.date_disable ).isAfter( now ),
                disB = !!b.date_disable && moment.unix( b.date_disable ).isAfter( now )

            if ( disA && !disB ) { return -1 }
            if ( !disA && disB ) { return 1 }
            if ( !disA && !disB ) { return sorter.locale( 'title' )( a, b ) }
            return sorter.unix( 'date_disable' )( a, b )
        },
        render: date =>
            (
                date && moment.unix( date ).isAfter( moment())
                    ? t( 'sharing.activeTill', { date: moment.unix( date ).format( 'DD MMM YYYY' ) })
                    : t( 'global.disabled' )
            ),
        stub: () => stub.line
    },
    {
        title:     t( 'columns.columnsCreationDate' ),
        dataIndex: 'created',
        width:     150,
        render:    date => moment.unix( date ).format( 'DD/MM/YYYY' ),
        sorter:    sorter.unix( 'created' ),
        stub:      () => stub.line
    },
    {
        title:     t( 'columns.columnsCount' ),
        dataIndex: 'links_count',
        width:     150,
        render:    ( count, row ) => (
            <div className="list-count-container" onClick={e => e.stopPropagation()}>
                <span className="list-count-data">{ cmp.state.refresh === row.id ? <Spinner /> : count }</span> <span className='list-count-refresh' onClick={() => cmp.refreshCount( row.id )}><Icons.Refresh /></span>
            </div>
        ),
        sorter: sorter.weight( 'links_count' ),
        stub:   () => stub.line
    },
    {
        title:     '',
        dataIndex: 'actions',
        onCell:    () => ({ className: 'align-right' }),
        render:    ( n, list ) => (
            <div onClick={e => e.stopPropagation()}>
                <Button
                    className = "action-btn icon-only no-border"
                    icon = { <Icons.Edit/> }
                    style = {{ marginRight: '1rem' }}

                    onClick = { cmp.props.onEdit( list ) }
                />
                <Dropdown
                    overlay={
                        <Menu style={{ width: 192 }}>
                            <Menu.Item key="edit" onClick={cmp.props.onEdit( list )}>
                                { t( 'global.edit' ) }
                            </Menu.Item>
                            <Menu.Item key="export" onClick={cmp.props.onExportCSV( list )}>
                                { t( 'global.exportCSV' ) }
                            </Menu.Item>
                            <Menu.Item key="remove" onClick={cmp.props.onRemove( list )} style={{ color: 'red' }}>
                                { t( 'global.remove' ) }
                            </Menu.Item>
                        </Menu>
                    }
                    trigger = "click"
                    getPopupContainer = { node => node.parentNode }
                    onVisibleChange = { cmp.lock( list ) }
                >
                    <Button className="action-btn icon-only" icon={<Icons.Ellipsis/>} />
                </Dropdown>
            </div>
        ),
        stub: () => (
            <React.Fragment>
                <Skeleton.Input active style={{ width: 32, marginRight: 8 }} />
                <Skeleton.Input active style={{ width: 32 }} />
            </React.Fragment>
        )
    },
])

export default columns
